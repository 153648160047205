<template>
  <v-container v-if="!loading">
    <h3>Support and Messaging</h3>
    <v-row>
      <v-col cols="12" sm="5" md="4">
        <v-card class="mx-auto" max-width="344" outlined>
          <v-list-item three-line>
            <v-list-item-content>
              <div class="overline mb-4">Marketing</div>
              <v-list-item-title class="headline mb-1"
                >Push Notification</v-list-item-title
              >
              <v-list-item-subtitle
                >List of push messages.</v-list-item-subtitle
              >
            </v-list-item-content>
          </v-list-item>

          <v-card-actions>
            <v-btn to="/admin/marketing/messages" text>View</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="12" sm="5" md="4">
        <v-card class="mx-auto" max-width="344" outlined>
          <v-list-item three-line>
            <v-list-item-content>
              <div class="overline mb-4">Support</div>
              <v-list-item-title class="headline mb-1"
                >Support Tickets</v-list-item-title
              >
              <v-list-item-subtitle
                >List of support tickets.</v-list-item-subtitle
              >
            </v-list-item-content>
          </v-list-item>

          <v-card-actions>
            <v-btn to="/admin/support/list" text>View</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="12" sm="5" md="4">
        <v-card class="mx-auto" max-width="344" outlined>
          <v-list-item three-line>
            <v-list-item-content>
              <div class="overline mb-4">Messaging</div>
              <v-list-item-title class="headline mb-1"
                >Send a Message</v-list-item-title
              >
              <v-list-item-subtitle
                >Communicate with producers or customers.</v-list-item-subtitle
              >
            </v-list-item-content>
          </v-list-item>

          <v-card-actions>
            <v-btn to="/messages" text>View</v-btn>
            <v-spacer></v-spacer>
            <v-btn to="/messages/new" text>New</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="5" md="4">
        <v-card class="mx-auto" max-width="344" outlined>
          <v-list-item three-line>
            <v-list-item-content>
              <div class="overline mb-4">Delivery</div>
              <v-list-item-title class="headline mb-1"
                >Monitor Deliveries</v-list-item-title
              >
              <v-list-item-subtitle
                >List of active and past deliveries.</v-list-item-subtitle
              >
            </v-list-item-content>
          </v-list-item>

          <v-card-actions>
            <v-btn to="/admin/pm/list" text>List</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'

/*
Don’t send me anything (aside from vital account emails such as password reset notifications)
*/
export default {
  components: {},
  computed: {
    ...mapGetters({
      isChef: 'isChef',
      user: 'user',
      loading: 'loading'
    })
  },
  mounted() {}
}
</script>
